<template>
  <v-dialog
    v-model="dlg.visible"
    :width="smAndDown ? 400 : 520"
    persistent
  >
    <v-card
      class="white"
      flat
    >
      <v-card-title>
        <span>{{ dlg.title }}</span>
        <v-spacer />
        <v-btn
          icon
          @click="onClose"
        >
          <v-icon>
            {{ btnClose.icon }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card
        class="blue-grey lighten-5"
        :class="[smAndDown ? 'pa-4' : 'pa-8']"
        flat
      >
        <div class="co-flex-col co-justify-center co-items-center co-w-full co-h-full">
          <span class="mb-8">选择参赛类型</span>

          <v-item-group
            mandatory
            @change="changeItem"
          >
            <v-row>
              <v-col
                v-for="(opt, index) in opts"
                :key="index"
                cols="12"
                :md="md"
              >
                <v-item
                  v-slot="{ active, toggle }"
                  :value="opt.name"
                >
                  <v-card
                    :color="active ? 'primary' : 'blue-grey lighten-3'"
                    class="d-flex align-center"
                    dark
                    :height="smAndDown ? 50: 100"
                    :width="smAndDown ? 100 : 140"
                    @click="toggle"
                  >
                    <v-scroll-y-transition>
                      <div
                        class="co-text-md flex-grow-1 text-center"
                        :class="[active ? 'co-font-600' : 'co-font-400']"
                      >
                        {{ opt.text }}
                      </div>
                    </v-scroll-y-transition>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-item-group>
        </div>
      </v-card>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          outlined
          width="80"
          @click="onConfirm"
        >
          {{ btnOk.text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import api from '@/api/co.api'
  export default {
    data () {
      return {
        btnClose: {
          icon: 'mdi-close'
        },
        dlg: {
          title: '活动参赛类型',
          visible: false
        },
        btnOk: {
          text: '确定'
        },
        types: {
          image: {
            name: 'image',
            icon: 'mdi-image-area',
            text: '单幅/组图'
          },
          video: {
            name: 'video',
            icon: 'mdi-video-box',
            text: '视频作品'
          },
          article: {
            name: 'article',
            icon: 'mdi-file',
            text: '专题故事'
          }
        },
        opts: [],
        md: 4,
        currType: ''
      }
    },
    computed: {
      smAndDown () {
        return this.$vuetify.breakpoint.smAndDown
      },
    },
    methods: {
      setOptions (submitType) {
        this.opts = []
        const arr = submitType.split(',')
        for (const i in arr) {
          const key = api.comm.trim(arr[i] || '')
          if (key) {
            const dst = this.types[key] || null
            if (dst) {
              this.opts.push(dst)
            }
          }
        }

        const cnt = this.opts.length
        if (cnt === 1) {
          this.md = 12
        } else if (cnt === 2) {
          this.md = 6
        } else {
          this.md = 4
        }
      },
      show (options) {
        this.setOptions(options.submitType)
        this.dlg.visible = true
      },
      onClose () {
        this.dlg.visible = false
      },
      onConfirm () {
        this.$emit('confirm', {
          type: this.currType
        })
        this.onClose()
      },
      changeItem (ev) {
        // console.log({ ev })
        this.currType = ev
      }
    }
  }
</script>
